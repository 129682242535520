import React from "react"

export default function FeaturesOrder() {

  return (
    <div className="section no-top-bottom">
      <div className="container columns">
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Fully Customizable</span> &mdash; Change menus, BOH setup, and kitchen notifications to best fit your business.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Accept Apple Pay &amp; Google Pay</span> &mdash; Leverage mobile wallets for quicker transactions.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Redemption Codes</span> &mdash; Allow guests to redeem discount codes from your marketing campaigns.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Dynamic Menu Items</span> &mdash; Add photos of your menu to engage guests and upsell.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

