import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function MarketsOrder() {

  const data = useStaticQuery(graphql`
    query {
      order_table: file(relativePath: { eq: "markets/order-table.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      room_service: file(relativePath: { eq: "markets/room-service.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      pickup: file(relativePath: { eq: "markets/pickup.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      order_seat: file(relativePath: { eq: "markets/order-seat.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      order_home: file(relativePath: { eq: "markets/order-home.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container columns mobile-columns">
        <div className="row clearfix">
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.order_table.childImageSharp.fluid}
                      alt="Order to Table"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Order to Table</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.room_service.childImageSharp.fluid}
                      alt="Room Service"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Room Service</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.pickup.childImageSharp.fluid}
                      alt="Pickup"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Pickup</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.order_seat.childImageSharp.fluid}
                      alt="Order to Seat"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Order to Seat</p>
                </div>
              </div>
            </div>
          </div>
          <div className="column one-five break-960-one-three break-half">
            <div className="padding">
              <div className="box outline no-top-bottom">
                <div className="box-padding">
                  <div className="hero">
                    <Img
                      fluid={data.order_home.childImageSharp.fluid}
                      alt="Order to Home"
                      loading="eager"
                    />
                  </div>
                  <p className="small text-white font-weight-500 no-top-bottom">Order to Home</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

