import { graphql, useStaticQuery } from "gatsby"

import ContactDemo from "../components/contactDemo"
import FeaturesOrder from "../components/features-order"
import Img from "gatsby-image"
// Import Components
import Layout from "../components/layout"
import MarketsOrder from "../components/markets-order"
import React from "react"
// Import Images
import SACKings_NBA from "../images/brands/SacKings-NBA.png"
import SEO from "../components/seo"

const Order = () => {
  const data = useStaticQuery(graphql`
    query {
      rooam_order_hero: file(
        relativePath: { eq: "phones/Rooam-Order-Hero.jpg" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooamOrderMenu: file(
        relativePath: { eq: "phones/Rooam-Order-Android-Menu.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooamOrderStatus: file(
        relativePath: { eq: "phones/Rooam-Order-Status.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Rooam Order - Pickup, room service, or in-seat ordering"
        description="Provide your guests with a touchless mobile web experience for pickup, room service, or in-seat ordering."
      />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Rooam Order</h1>
          <p className="lead text-light-grey no-bottom">
            Whether you operate a restaurant, hotel, or a sporting arena,
            Rooam's ordering solutions provide your guests with a touchless
            mobile web experience for pickup, room service, or in-seat ordering.
          </p>
        </div>
      </div>

      <div className="section">
        <div className="container float-middle" style={{ maxWidth: 660 }}>
          <Img
            fluid={data.rooam_order_hero.childImageSharp.fluid}
            alt="A Sacramento Kings menu screen inside an Google Pixel 4"
            loading="eager"
          />
        </div>
      </div>

      <div className="section steps no-top">
        <div className="container">
          <h3 className="more text-white no-top">How it works</h3>
        </div>
        <div className="container columns mobile-columns">
          <div className="row clearfix">
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle blue no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      1
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">Scan</p>
                <p className="small text-light-grey no-bottom">
                  Guest scans QR code or visits locations website to start their
                  order.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle purple no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      2
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Select
                </p>
                <p className="small text-light-grey no-bottom">
                  Views the menu and adds items to the cart with modifiers.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle pink no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      3
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">Pay</p>
                <p className="small text-light-grey no-bottom">
                  Guest pays for the order and selects pickup or delivery to
                  their table, room, or seat.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle orange no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      4
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Pickup or Delivery
                </p>
                <p className="small text-light-grey no-bottom">
                  Guest gets notified when items are ready for pickup, or being
                  brought to them directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container columns">
          <div className="row clearfix">
            <div className="column break-full">
              <div className="padding">
                <div className="box background-dark-grey no-top-bottom">
                  <div className="box-padding no-bottom">
                    <p className="small text-white font-weight-700 no-top">
                      Pickup
                    </p>
                    <p
                      className="lead text-grey font-weight-600 no-top-bottom"
                      style={{ minHeight: 105 }}
                    >
                      A cost-effective solution for takeout or curbside pick-up
                      orders.
                    </p>
                    <div className="payment-option">
                      <a
                        href="https://media.rooam.co/video/b2b/Rooam-Order-Pickup.mp4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={data.rooamOrderMenu.childImageSharp.fluid}
                          alt="Rooam Order menu screen inside an Android phone"
                          loading="eager"
                        />
                        <div className="play-btn">
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="svg-icon"
                              viewBox="0 0 24 24"
                            >
                              <path d="M3 22v-20l18 10-18 10z" />
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column break-full">
              <div className="padding">
                <div className="box background-dark-grey no-top-bottom">
                  <div className="box-padding no-bottom">
                    <p className="small text-white font-weight-700 no-top">
                      On-Premise Dining
                    </p>
                    <p
                      className="lead text-grey font-weight-600 no-top-bottom"
                      style={{ minHeight: 105 }}
                    >
                      A touchless mobile web experience for orders to the table,
                      hotel room, or arena seat.
                    </p>
                    <div className="payment-option">
                      <a
                        href="https://media.rooam.co/video/b2b/Rooam-Order.mp4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={data.rooamOrderStatus.childImageSharp.fluid}
                          alt="Rooam Order status screen inside an iPhone"
                          loading="eager"
                        />
                        <div className="play-btn">
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="svg-icon"
                              viewBox="0 0 24 24"
                            >
                              <path d="M3 22v-20l18 10-18 10z" />
                            </svg>
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section more-top">
        <div className="container text-center">
          <h1 className="text-white no-top">
            &ldquo;We are committed to utilizing technology to reimagine how we
            engage with our fans and we’re thrilled to launch an industry-first
            curbside pickup program, powered by Rooam.&rdquo;
          </h1>
          <p className="text-grey">
            <span className="font-weight-600">Eric King</span> &mdash;
            Sacramento Kings, VP of Technology
          </p>
          <div className="peak-wrap no-bottom">
            <div className="icon" style={{ width: 100 }}>
              <img src={SACKings_NBA} alt="Sacramento Kings and NBA Logos" />
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container">
          <h3 className="more text-white no-top">Where to use Rooam Order</h3>
        </div>
        <MarketsOrder />
      </div>

      <div className="section">
        <FeaturesOrder />
      </div>

      <div className="section no-top-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Request a demo</h1>
        </div>
      </div>

      <div className="section no-top">
        <ContactDemo formName="request-demo-order" />
      </div>
    </Layout>
  )
}

export default Order
